$blue: #0051fd;
$pink: #fc6cd6;
$yellow: #fdf701;
$purple: #5e3bfa;
$teal: #02f3fc;

$light-blue: #3269ff;
$dark-blue: #003fc6;

html, body, #root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.App {
  font-family: 'Roboto Slab', sans-serif;
  font-size: 14px;
  height: 100%;

  a {
    color: $blue;
  }

  button {
    font-family: 'Roboto Slab', sans-serif;
    font-size: 14px;
    cursor: pointer;
    padding: 5px 20px;
    border-radius: 20px;
    border: 1px solid black;

    &.primary {
      border: 1px solid white;
      background: $blue;
      color: white;

      &:hover {
        background-color: $light-blue;
      }
      &:active {
        background-color: $dark-blue;
      }
    }

    &.secondary {
      border: 1px solid $blue;
      background: white;
      color: $blue;
      border-color: $blue;

      &:hover {
        border: 1px solid white;
        background-color: $light-blue;
        color: white;
      }
      &:active {
        border: 1px solid white;
        background-color: $dark-blue;
        color: white;
      }
    }

    &.selected {
      cursor: default;
      background-color: $blue;
      color:white;
      border: 1px solid white;
    }

    &[disabled] {
      cursor: not-allowed;
      border: 1px solid #999999;
      background-color: #cccccc;
      color: #666666;

      &:hover {
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
      }
    }
  }

  hr {
    border: none;
    background-size: 10px 100%; /* Set the size of each dashed segment */
    background-repeat: repeat-x; /* Repeat the background image horizontally */
    height: 2px;
    width: 100%;
    background-image: linear-gradient(to right, $pink 25%, rgba(0,0,0,0) 25%);
    fill: rgb(202, 162, 82)
  }
}
.TakeoutCounter {
  .foreground {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  h1 {
    font-size: 20px;
    margin: 0px;
    text-align: center;
    font-weight: normal;
  }

  .container {
    background-color: white;
    border: 1px solid rgb(229, 231, 235);
    border-radius: 1.5rem;
    --tw-shadow: 0px 2px 30px 0px rgba(0,0,0,.15),0px 0px 4px 0px rgba(0,0,0,.15);
    --tw-shadow-colored: 0px 2px 30px 0px var(--tw-shadow-color),0px 0px 4px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);

    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 100%;
    width: fit-content;
    padding: 20px;
    gap: 20px;
  }
  
  .controls {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 600px;
    gap: 20px;

    .mode-controls {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      width: 100%;
    }

    .token-id-input {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      width: 100%;

      input {
        width: 50px;
        text-align: left;
      }
    }
  }

  .png-controls {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 600px;
  }

  .control {
    display: flex;
    flex-direction: column;
    width: 100%;

    .row {
      width: 100%;
      display: flex;
    }

    label {
      flex-grow: 1;
    }

    input[type="range"] {
      width: 100%;
    }

    input[type="number"] {
      width: 60px; // Adjust as needed for input width
      text-align: left;
    }

    .units {
      width: 1rem;
      margin-left: 5px;
    }
  }

  .canvas-container {
    overflow: auto;
    max-width: 100%;
  }
}
@import '../../styles/palette.scss';

.Sign {
  font-family: 'Roboto Slab', sans-serif;
  font-size: 24px;
  margin-top: 0;
  text-align: center;

  a {
    text-decoration: none;
  }

  .line-1 {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;

    .mom-img {
      transform: scale(2);
      image-rendering: pixelated;
    }

    .pop-img {
      transform: scaleX(-2) scaleY(2);
      image-rendering: pixelated;
    }

    .text {
      position: relative;
      top: 10px;
    }
  }

  .line-2 {
    font-size: 1.1em;
  }

  .text {
    filter: brightness(80%);
  }

  .mom {
    color: $pink;
    font-size: smaller;
  }
  .and {
    color: $yellow;
    font-size: smaller;
  }
  .pop {
    color: $blue;
    font-size: smaller;
  }
  .onchain-ice-cream-shop {
    color: $purple;
  }
}
@import '../../styles/palette.scss';

.HomePage {
  .foreground {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    
    .container {
      background-color: white;
      
      margin: auto;
      padding: 20px;
      //box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
      border-radius: 20px;
      max-width: 800px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      --tw-shadow: 0px 2px 30px 0px rgba(0,0,0,.15),0px 0px 4px 0px rgba(0,0,0,.15);
      --tw-shadow-colored: 0px 2px 30px 0px var(--tw-shadow-color),0px 0px 4px 0px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);

      .menu {
        border: 4px inset slategray;
        background: #444;
        color: white;
        font-family: 'Zeyada';
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .cost-header {
          //font-family: 'Dancing Script';
          font-size: 18pt;
        }
        
        .flavor-header {
          font-size: 18pt;
          text-decoration: underline;
        }

        .flavors {
          font-size: 16pt;
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
          grid-auto-rows: 1.5em;
          gap: 5px;
          width: 100%;
          max-width: 400px;

          .flavor-item {
            line-height: .75em;
            align-self: center;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
          }
  
          .flavor-item:last-child {
            grid-column: 1 / -1;
          }
        }
      }
    }

    .welcome-line {
      display: flex;
      align-items: center;
      
      .welcome-message {
        width: 100%;
        text-align: left;
      }

      .address-info {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 5px;
      }

      .disconnect-button {
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
          width: 20px;
          height: 20px;
          
          path {
            stroke: $blue;
          }
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: center;
      gap: 5px;
    }

    .quantity-selector, .mint-button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      button {
        flex-grow: 0;
      }
    }

    .mint-button {
      flex: 1;
    }
  }

  .connected {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
  }

  .scoop-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  svg.ice-cream {
    max-width: 500px;
    border: 1px solid black;
    //border-radius: 20px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
  }
}
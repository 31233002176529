.Footer {
  display: flex;
  flex-direction: column;

  a.active {
    color: black;
    font-weight: bold;
    text-decoration: none;
    cursor: default;
  }

  .row {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-bottom: 1em;
  }
}